import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Button from "../components/Button";
import SEO from "../components/SEO";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Autoplay } from 'swiper/modules';

const Projects = (props: any) => {
  const allProjects = props.data?.allPrismicProject?.nodes || [];

  return (
    <>
      <SEO {...props.data.prismicSeo.data} />
      <div>
        <Swiper
          direction={'vertical'}
          pagination={{
            clickable: true,
          }}
          loop={true}
          modules={[Pagination, Autoplay]}
          autoplay={{delay: 5500, disableOnInteraction: false}}
          // @ts-ignore
          style={{'--swiper-pagination-color': '#ffffff', '--swiper-pagination-bullet-inactive-color': '#ffffff', '--swiper-pagination-bullet-inactive-opacity': '0.6', '--swiper-pagination-right': '5%' 
        }}
        >
          {allProjects.map((project, projectIndex) => (
            <SwiperSlide key={projectIndex} lazy={true}>
              <div>
                <GatsbyImage
                  image={
                    project.data.cover.localFile.childImageSharp.gatsbyImageData
                  }
                  key={`project-image-${projectIndex}`}
                  className="swiper-project-image"
                  loading="lazy"
                  alt={""}
                />
                <div className="swiper-project-content">
                  <p className="cap study-cap-color">
                    {"//"}&nbsp;{allProjects[projectIndex].data.project_area}
                  </p>
                  <p className="heading white">
                    {allProjects[projectIndex].data.title}
                  </p>
                  <p className="p1 white" style={{marginBottom: '3rem'}}>{allProjects[projectIndex].data.intro}</p>
                  <Button
                    outerClass="mosano-button-outer fit-content"
                    buttonClass="button-container fit-content"
                    buttonHref={allProjects[projectIndex].uid}
                    buttonHrefClass="button-read-more white w-button"
                    buttonLabel="read more"
                    buttonLine="button-box white"
                    spacer={false}
                  />
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};

export default Projects;

export const pageQuery = graphql`
  query ProjectsQuery {
    prismicSeo(data: { name: { eq: "Mosano | Case Studies" } }) {
      data {
        name
        title
        description
        keywords
        canonical_url {
          url
        }
        image {
          url
        }
      }
    }
    allPrismicProject(sort: { fields: first_publication_date, order: DESC }) {
      nodes {
        uid
        data {
          project_area
          title
          intro
          cover {
            alt
            url
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 90
                  tracedSVGOptions: { background: "#1e22aa", color: "#333" }
                  placeholder: BLURRED
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
        id
      }
    }
    prismicCookieConsent(uid: { eq: "cookie_consent" }) {
      data {
        setting_button_label
        accept_button_label
        decline_button_label
        close_button_label
        close_icon_alt
        title {
          text_1
          text_2
          text_3
        }
        cookies_categories {
          name
          details {
            text
          }
          policy_link {
            url
          }
          policy_link_text
          expand_button_label
          status
        }
      }
    }
  }
`;
